import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Survey from "./pages/Survey";
import MultiLevel from "./pages/MultiLevel";
import Scratch_Card from "./pages/Scratch_Card";
import Quiz from "./pages/Quiz";
import Carrom from "./pages/Carrom";
// import ThisOrThat from "./pages/ThisOrThat";
import GameZone from "./pages/GameZone";
import ThisOrThat from "./pages/gameZone/ThisOrThat";
import GameZoneQuiz from "./pages/gameZone/Quiz";
import HowToPlay from "./pages/gameZone/HowToPlay";
import SnakesAndLadders from "./pages/SnakesAndLadders";
import DiscFootball from "./pages/DiscFootball";
import USCarrom from "./pages/CarromUs";
import { AnimatePresence } from "framer-motion";
import DiscFootballUs from "./pages/DiscFootballUs";
import BallPool8 from "./pages/8BallPool";
import BallPool8Us from "./pages/8BallPoolUs";
import NudgeCarrom from "./pages/NudgeCarrom";
import Nudge8BallPool from "./pages/Nudge8BallPool";
import NudgeSnakesnLadders from "./pages/NudgeSnakesnLadders";
import NudgeDiscFootball from "./pages/NudgeDiscFootball";

function App() {
  return (
    <Router>
      <AnimatePresence mode="wait">
        <Routes>
          <Route caseSensitive path="/survey" element={<Survey />} />
          {/* <Route caseSensitive path="/this_or_that" element={<ThisOrThat />} /> */}
          <Route caseSensitive path="/uscarrom" element={<USCarrom />} />
          <Route caseSensitive path="/carrom" element={<Carrom />} />
          <Route caseSensitive path="/snakes-and-ladders" element={<SnakesAndLadders />} />
          <Route caseSensitive path="/disc-football" element={<DiscFootball />} />
          <Route caseSensitive path="/nudge-disc-football" element={<NudgeDiscFootball />} />
          <Route caseSensitive path="/nudge-carrom" element={<NudgeCarrom />} />
          <Route caseSensitive path="/nudge-8ball-pool" element={<Nudge8BallPool />} />
          <Route caseSensitive path="/nudge-snakes-and-ladders" element={<NudgeSnakesnLadders />} />
          <Route caseSensitive path="/us-disc-football" element={<DiscFootballUs />} />
          <Route caseSensitive path="/8ball-pool" element={<BallPool8 />} />
          <Route caseSensitive path="/us-8ball-pool" element={<BallPool8Us />} />
          {/* <Route caseSensitive path="/game-zone/how-to-play" element={<HowToPlay />} /> */}
          <Route caseSensitive path="/game-zone/quiz" element={<GameZoneQuiz />} />
          <Route caseSensitive path="/game-zone/this-or-that" element={<ThisOrThat />} />
          <Route caseSensitive path="/game-zone" element={<GameZone />} />
          <Route caseSensitive path="/multilevel" element={<MultiLevel />} />
          <Route caseSensitive path="/scratch-card" element={<Scratch_Card />} />
          <Route caseSensitive path="/quiz" element={<Quiz />} />
        </Routes>
      </AnimatePresence>
    </Router>
  );
}

export default App;
