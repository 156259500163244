import React, { useEffect, useState } from "react";
import instance from "../extras/baseUrl";

const QuestionAndOptions = [
  {
    question: "What dish is this?",
    img: "https://media.cntraveller.in/wp-content/uploads/2020/05/homemade-paneer-recipes-1366x768.jpg",
    options: ["Panner", "Chicken", "Roasted", "Juice"],
    answer: "Panner",
  },
  {
    question: "Who went to moon first?",
    options: ["Yuri Gagarin", "Neil Armstrong", "Buzz Aldrin", "Alan Shepard"],
    answer: "Neil Armstrong",
  },
  {
    question: "When is Independence Day of India?",
    options: ["15th August", "26th January", "26th December", "2nd October"],
    answer: "15th August",
  },
];

const Quiz = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const gameSettingId = urlParams.get("gameSettingId");
  const campaignId = urlParams.get("campaignId");
  const clientId = urlParams.get("clientId");
  const userId = urlParams.get("userId");
  const token = urlParams.get("token");

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerOptions, setSelectedAnswerOptions] = useState({});
  const [completed, setCompleted] = useState(false);
  const [score, setScore] = useState(0);

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});

  const loadSettings = async () => {
    setLoading(true);
    try {
      const res = await instance.get(
        `/game_settings?gameKey=quiz&gameSettingId=${gameSettingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const gameSetting = res.data.data;
      setSettings(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (gameSettingId && token) {
      loadSettings();
    }
  }, [gameSettingId, token]);

  const onOptionClick = (option) => {
    setSelectedAnswerOptions({
      ...selectedAnswerOptions,
      [currentQuestion]: option,
    });
  };

  const onSubmit = () => {
    setTimeout(() => {
      if (currentQuestion < settings?.quizzes?.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        QuestionAndOptions?.map((question, index) => {
          const selectedAns = selectedAnswerOptions[index];
          if (selectedAns === question?.answer) {
            setScore((prev) => prev + 1);
          }
        });
        setCompleted(true);
      }
    }, 800);
  };

  return (
    <div className="min-h-[100vh] py-8 px-6 sm:px-10 flex justify-center gap-y-4">
      {completed ? (
        <div className="gap-y-4 w-full flex flex-col h-auto max-w-xs">
          <h2 className="text-2xl font-bold text-center">
            {settings?.resultsFormat?.heading}
          </h2>
          <div className="flex flex-col text-white items-center space-y-2 py-6 px-4 rounded-md">
            <div className="relative">
              <img
                className="w-56 h-56 object-contain"
                src={settings?.resultsFormat?.rewardIconBgImageUrl}
                alt=""
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  className="w-24 h-24 rounded-full object-contain"
                  src={settings?.resultsFormat?.rewardIconUrl}
                  alt=""
                />
              </div>
            </div>
            <div className="uppercase text-lg">Your Score</div>
            <div className="font-bold text-xl">
              3/{settings?.quizzes?.length}
            </div>
          </div>
          <div className="text-lg text-center"
          style={{
            color: settings?.resultsFormat?.ctaTextColor
          }}
          >
            {settings?.resultsFormat?.subHeading}
          </div>
          <button 
          style={{
            backgroundColor: settings?.resultsFormat?.ctaButtonColor,
            color: settings?.resultsFormat?.ctaButtonText

          }}
          className="p-4 bg-blue-600 mt-auto rounded-md w-full uppercase text-white font-semibold">
            Redeem
          </button>
        </div>
      ) : (
        Object.keys(settings)?.length > 0 && (
          <div className="gap-y-4 w-full flex flex-col">
            <h2 className="text-2xl font-bold">Quiz</h2>
            {/* Progress Bar */}
            <div className="relative w-full h-3 rounded-full bg-slate-200">
              <div
                className="absolute inset-0 h-full rounded-full transition-all duration-300 ease-in-out"
                style={{
                  width: `${
                    (Object.keys(selectedAnswerOptions)?.length /
                      settings?.quizzes?.length) *
                    100
                  }%`,
                  backgroundColor: settings?.questionsFormat?.accentColor,
                }}
              />
            </div>
            {/* Quiz */}
            <div
              className="text-xl font-semibold w-full"
              style={{
                color:
                  settings?.questionsFormat?.questions?.questionStyle
                    ?.fontColor,
                fontWeight:
                  settings?.questionsFormat?.questions?.questionStyle
                    ?.fontStyle,
              }}
            >
              {settings?.quizzes[currentQuestion].question}
            </div>
            {settings?.quizzes[currentQuestion]?.imageUrl && (
              <img
                className="rounded-md w-full h-44 object-cover"
                src={settings?.quizzes[currentQuestion]?.imageUrl}
                alt=""
              />
            )}
            <div className="flex flex-col w-full gap-4">
              {settings?.quizzes[currentQuestion].options.map(
                (option, index) => {
                  const active =
                    selectedAnswerOptions[currentQuestion] == option;
                  return (
                    <div
                      key={index}
                      onClick={() => onOptionClick(option)}
                      className={`rounded-md gap-4 w-full flex items-center cursor-pointer ${
                        selectedAnswerOptions[currentQuestion] == option
                          ? "bg-blue-600 text-white"
                          : "bg-gray-300 "
                      } hover:bg-blue-600 hover:text-white font-semibold border-black p-4 w-full`}
                      style={{
                        backgroundColor:
                          settings?.questionsFormat?.questions?.optionsBgColor,
                        color:
                          settings?.questionsFormat?.questions?.optionsTextStyle
                            ?.fontColor,
                        fontWeight:
                          settings?.questionsFormat?.questions?.optionsTextStyle
                            ?.fontStyle,
                      }}
                    >
                      <div className="h-8 w-8 bg-white text-black font-bold flex justify-center items-center text-center rounded-full">
                        {index + 1}
                      </div>
                      <div>{option}</div>
                    </div>
                  );
                }
              )}
            </div>
            <button
              type="button"
              onClick={onSubmit}
              className="p-4 bg-blue-600 rounded-md w-full text-white font-semibold"
            >
              Submit
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default Quiz;
