import React, { useState, useCallback, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import splash from "../assets/carrom.mp4";
import gif from "../assets/carrom.gif";
import jwtDecode from "jwt-decode";
import instance from "../extras/baseUrl";
import axios from "axios";
import { CLIENT_URL } from "../extras/constants";

const BallPool8 = () => {
  const [show, setShow] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const payload = urlParams.get("payload");

  const [verified, setVerified] = useState(false);

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "build/8ballPool/Build 5.1 IN.loader.js",
    dataUrl: "build/8ballPool/Build 5.1 IN.data",
    frameworkUrl: "build/8ballPool/Build 5.1 IN.framework.js",
    codeUrl: "build/8ballPool/Build 5.1 IN.wasm",
    productName: "My Game",
    productVersion: "1.0.0",
    companyName: "Developer",
  });
  // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.
  const loadingPercentage = Math.round(loadingProgression * 100);

  // We'll use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const handleChangePixelRatio = useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  const decodeToken = () => {
    const decoded = jwtDecode(payload);
    return decoded;
  };

  const verifyToken = async () => {
    const decoded = jwtDecode(token);
    console.log(decoded, "TOKEn");

    try {
      const res = await instance.post("/clients/verify", {
        clientId: decoded?._id,
      });
      if (res.data?.status === "success") {
        setVerified(true);
      }
      console.log("Client Verified");
    } catch (err) {
      console.log(err);
    }
  };

  const handleApiClose = async (instanceId, winnerId) => {
    try {
      var form = new FormData();
      form.append("instanceId", instanceId);
      form.append("winnerId", winnerId);

      const res = await axios.post(CLIENT_URL, form, {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmdWxsTmFtZSI6Ik51ZGdlIiwicm9sZSI6InNlcnZlciIsImlhdCI6MTY0MjIzNjYzMH0.nZ8xNK-qIYJ6thekIpyKtbFQY57glDdLJr5Ds61To7M",
        },
      });

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        const {
          gameType,
          player_name,
          playerId,
          player_image,
          player2_name,
          player2_image,
          instanceId,
          difficulty,
          iat,
          exp,
        } = decodeToken();
        handleApiClose(instanceId, "bot");
        // Dispose your WebGL game here
        window?.app?.postMessage("close");
        // window?.FlutterInappwebview?.callSHandler("app", "close");
        console.log("ALERTS");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleGameOver = useCallback((data) => {
    console.log(data, "DATA");
    window?.ReactNativeWebView?.postMessage("close");
    window?.app?.postMessage("close");
    window?.FlutterInappwebview?.callHandler("app", "close");
    // window?.close()
    // alert("CLOSE")
    // setShow(false);
  }, []);

  useEffect(() => {
    addEventListener("GameOver", handleGameOver);
    return () => {
      removeEventListener("GameOver", handleGameOver);
    };
  }, [addEventListener, removeEventListener, handleGameOver]);

  useEffect(() => {
    if (token) {
      verifyToken();
    }
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 8000);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      //   setTimeout(() => {
      try {
        const {
          gameType,
          player_name,
          playerId,
          player_image,
          player2_name,
          player2_image,
          instanceId,
          difficulty,
          iat,
          exp,
        } = decodeToken();
        sendMessage(
          "GameApiCalls",
          "GetData",
          JSON.stringify({
            gameType: gameType,
            player_name: player_name,
            playerId: playerId,
            player_image: player_image,
            player2_name: player2_name,
            player2_image: player2_image,
            instanceId: instanceId,
            difficulty: difficulty,
            iat: iat,
            exp: exp,
          })
        );
      } catch (err) {
        console.error(err);
        window?.ReactNativeWebView?.postMessage("close");
        window?.app?.postMessage("close");
        window?.FlutterInappwebview?.callHandler("app", "close");
      }
      //   }, 2000);
    }
  }, [isLoaded, payload]);

  function handleMessageFromUnity(message) {
    console.log(message, "MESS");
  }

  return (
    <div className="overflow-hidden h-screen">
      {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="h-screen w-screen flex items-center justify-center">
          {iOS() ? (
            <img className="max-w-xs" src={gif} alt="" />
          ) : (
            <video
              className="max-w-xs"
              src={splash}
              playsInline
              autoPlay
              muted
              loop
            />
          )}
        </div>
      )}
      {/* {isLoaded === false && (
        // We'll conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="loading-overlay">
          <p>Loading Game... ({loadingPercentage}%)</p>
        </div>
      )} */}
      {/* {show === false && (
        <>
          {isLoaded === false && (
            // We'll conditionally render the loading overlay if the Unity
            // Application is not loaded.
            <div className="h-screen w-screen flex items-center justify-center">
              <video
                className="max-w-xs"
                src={splash}
                playsInline
                autoPlay
                muted
                loop
              />
            </div>
          )}
        </>
      )} */}
      {/* <button onClick={setData}>Click</button> */}
      {/* {
        isLoaded === true && */}
      {verified && (
        <Unity
          unityProvider={unityProvider}
          style={{ width: "100vw", height: "100vh" }}
          devicePixelRatio={window.devicePixelRatio}
          onMessage={handleMessageFromUnity}
        />
      )}
      {/* } */}
    </div>
  );
};

export default BallPool8;
