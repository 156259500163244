import React, { useEffect, useState } from "react";
import ChevronRight from "@heroicons/react/24/outline/ChevronRightIcon";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import instance from "../extras/baseUrl";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import Loader from "../components/Loader";
import { isGreaterThan24Hours } from "../extras/utils";

const GameList = [
  {
    name: "thisorthat",
    image:
      "https://th.bing.com/th/id/OIP.jJDGvYgxtBDVvyxhUQCkogHaFQ?pid=ImgDet&rs=1",
    link: "/game-zone/this-or-that",
  },
  {
    name: "Quiz",
    image:
      "https://www.thelearningapps.com/wp-content/uploads/2021/01/quiz-comic-pop-art-style_175838-505.jpg",
    link: "/game-zone/quiz",
  },
];

const GameZone = () => {
  const navigate = useNavigate();

  const GetLink = (key) => {
    switch (key) {
      case "thisorthat":
        return "/game-zone/this-or-that";
      case "gamezonequiz":
        return "/game-zone/quiz";
      default:
        return "/game-zone";
    }
  };

  // useState hooks
  const [config, setConfig] = useState({});
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [count, setCount] = useState(10);

  // get query params from the url
  const urlParams = new URLSearchParams(window.location.search);
  const gameZoneId = urlParams.get("gameZoneId");
  const nudgeUserId = urlParams.get("nudgeUserId");
  const token = urlParams.get("token");

  const getGameStatus = async () => {
    console.log("Status");
    try {
      const res = await instance.get(
        `/gamezone/games/status?nudgeUserId=${nudgeUserId}&gameZoneId=${gameZoneId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatus(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const TrackGameZone = async () => {
    try {
      const payload = {
        nudgeUserId: nudgeUserId,
        gameZoneId: gameZoneId,
      };
      const res = await instance.post(`/gamezone/track`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  // get the gamezone config
  const getGameZone = async () => {
    setLoading(true);
    setIsLoading(true);
    try {
      const res = await instance.get(`/gamezone/${gameZoneId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setConfig(res.data.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  };

  useEffect(() => {
    if (nudgeUserId && gameZoneId && token) {
      TrackGameZone();
      getGameStatus();
    }
  }, [nudgeUserId, gameZoneId, token]);

  useEffect(() => {
    if (gameZoneId && token) {
      getGameZone();
    }
  }, [gameZoneId, token]);

  const handleLoadMore = () => {
    if (config?.games?.length > count) {
      setCount((prev) => prev + 10);
      return;
    }
    return;
  };

  const sortGames = (arr) => {
    return arr.sort((a, b) => {
      const vara = status?.find(
        (stat) => stat?.gameSettingId === a?.gameSettingId?._id
      )?.completed
        ? "done"
        : "new";
      console.log(vara);
      const varb = status?.find(
        (stat) => stat?.gameSettingId === b?.gameSettingId?._id
      )?.completed
        ? "done"
        : "new";
      if (vara === "new" && varb === "done") {
        return -1; // Place "new" before "done"
      } else if (vara === "done" && varb === "new") {
        return 1; // Place "done" after "new"
      } else {
        return 0; // Preserve the order of other elements
      }
    });
  };

  return !loading ? (
    <div
      style={{
        backgroundColor: config?.bgColor,
        backgroundImage: "url(" + config?.bgImageUrl + ")",
      }}
      className="space-y-2 font-fieldwork10 min-h-screen bg-cover bg-center bg-no-repeat"
    >
      <Loader isLoading={isLoading} />
      {/* <div className='w-full h-56 bg-blue-500'></div> */}
      <img
        src={config?.bannerImageUrl}
        className="w-full h-auto  object-contain"
      />
      {/* <img src="" alt="" /> */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 px-4 py-2  items-center">
        {sortGames(config?.games)
          ?.slice(0, count)
          ?.filter((_) => {
            const gameStats = status?.find(
              (stat) => stat?.gameSettingId === _?.gameSettingId?._id
            );
            return !isGreaterThan24Hours(gameStats?.dateCompleted, new Date());
          })
          ?.map((_, index) => {
            const gameStats = status?.find(
              (stat) => stat?.gameSettingId === _?.gameSettingId?._id
            );

            return (
              // !isGreaterThan24Hours(gameStats?.dateCompleted, new Date()) && (
              <div
                key={index}
                onClick={() => {
                  if (
                    !status?.find(
                      (stat) => stat?.gameSettingId === _?.gameSettingId?._id
                    )?.completed
                  ) {
                    navigate(
                      GetLink(_?.gameKey) +
                        `?nudgeUserId=${nudgeUserId}&gameSettingId=${_?.gameSettingId?._id}&key=${_?.gameKey}&token=${token}&gameZoneId=${gameZoneId}`
                    );
                  }
                }}
                className="col-span-1  shadow-black shadow1 relative flex flex-col justify-between w-full h-48 rounded-lg bg-pink-400"
              >
                <img
                  src={_?.gameSettingId?.thumbnailImageUrl}
                  className="h-40 w-full rounded-t-lg object-cover"
                  alt=""
                />
                {gameStats?.completed ? (
                  <div className="absolute top-2 right-2 py-1 px-2 bg-red-400 text-red-800 rounded-md text-xs font-bold">
                    DONE
                  </div>
                ) : (
                  <div className="absolute top-2 right-2 py-1 px-2 bg-green-400 text-green-800 rounded-md text-xs font-bold">
                    NEW
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: config?.primaryColor,
                  }}
                  className="flex items-center rounded-b-lg justify-between py-2 px-4  text-white font-medium"
                >
                  <span className="truncate">{_?.gameSettingId?.name}</span>
                  <ChevronRight className="w-4 h-4" />
                </div>
              </div>
            );
          })}
      </div>
      {sortGames(config?.games)
        ?.slice(0, count)
        ?.filter((_) => {
          const gameStats = status?.find(
            (stat) => stat?.gameSettingId === _?.gameSettingId?._id
          );
          return !isGreaterThan24Hours(gameStats?.dateCompleted, new Date());
        })?.length === 0 && (
        <div className="flex flex-col items-center space-y-4">
          <img
            className="w-60 h-auto object-contain"
            src="/assets/Empty.png"
            alt=""
          />
          <h2 className="text-2xl font-semibold">No games left!</h2>
          <div className="text-lg text-center">
            You’ve completed all the games <br />
            We’ll be adding more soon
          </div>
        </div>
      )}
      {config?.games?.length > count && (
        <div
          onClick={handleLoadMore}
          className="flex justify-center text-center py-6 items-center"
        >
          Load More <ChevronDownIcon className="h-5 w-5" />
        </div>
      )}
    </div>
  ) : (
    <div></div>
  );
};

export default GameZone;
