import React from "react";
import { BeatLoader } from "react-spinners";

const Loader = ({ isLoading, bgColor }) => {
  if (isLoading) {
    return (
      <div 
      style={{
        backgroundColor: bgColor
      }}
      className="absolute backdrop-blur-lg bg-white z-50 inset-0 flex items-center h-screen justify-center">
        <BeatLoader size={10} color="#9E3EC5"  />
      </div>
    );
  }
  return null
};

export default Loader;
