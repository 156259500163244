import React, { useEffect, useState } from "react";
import ScratchCard from "../components/ScratchCard";
import card from "../assets/card3.png";
import ChevronLeft from "@heroicons/react/24/outline/ChevronLeftIcon";
import XMark from "@heroicons/react/24/outline/XMarkIcon";
import Clipboard from "@heroicons/react/24/outline/ClipboardIcon";
import instance from "../extras/baseUrl";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const Scratch_Card = () => {
  const { width, height } = useWindowSize();

  var source = axios.CancelToken.source();

  const urlParams = new URLSearchParams(window.location.search);
  const gameSettingId = urlParams.get("gameSettingId");
  const campaignId = urlParams.get("campaignId");
  const clientId = urlParams.get("clientId");
  const userId = urlParams.get("nudgeUserId");
  const token = urlParams.get("token");

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [settings, setSettings] = useState({});
  const [stats, setStats] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [finishPercentage, setFinishPercentage] = useState(96);
  const [task, setTask] = useState(false);
  const [scratched, setScratched] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const settingss = {
    width: 210,
    height: 210,
    image: card,
    finishPercent: 96,
    onComplete: () => updateScratched(),
  };

  const buttonClick = (deepLink) => {
    window?.ReactNativeWebView?.postMessage("close");
    window?.app?.postMessage(deepLink);
    window?.FlutterInappwebview?.callHandler("app", deepLink);
  };

  const updateScratched = async () => {
    const payload = {
      clientId: clientId,
      campaignId: campaignId,
      nudgeUserId: userId,
      eventId: cardId?.main?.event,
    };
    try {
      const res = await instance.put("/scratch-card", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data);
      await getScratchCardStats();
    } catch (err) {
      console.log(err);
    }
  };

  const loadSettings = async () => {
    setLoading(true);
    try {
      const res = await instance.get(
        `/game_settings?gameKey=scratchcard&gameSettingId=${gameSettingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const gameSetting = res.data.data;
      setSettings(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getScratchCardStats = async () => {
    try {
      const res = await instance.get(
        `/stats/scratch-card?clientId=${clientId}&campaignId=${campaignId}&nudgeUserId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      );
      if (res.data.message) {
        setTask(true);
        return;
      }
      setStats(res?.data?.data);
      setTask(false);
      // console.log(res.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (gameSettingId) {
      loadSettings();
    }
    // console.log("Get Sratch Card stats: ", clientId, campaignId, userId);
    if (clientId && campaignId && userId) {
      console.log("Get Sratch Card stats: ");
      getScratchCardStats();
    }
    return () => {
      // cancel teh axios call as a cleanup function
      console.log("Cancel Axios UseEffect Cleanup");
      // source.cancel()
    };
  }, [gameSettingId, clientId, campaignId, userId]);

  useEffect(() => {
    if (selectedId) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "visible";
  }, [selectedId]);

  const handleScractchCardClose = async () => {
    if (percentage > 0 && percentage < finishPercentage) {
      setFinishPercentage(0);
      await updateScratched();
      setTimeout(() => {
        setShow(false);
        setSelectedId(null);
        setFinishPercentage(96);
        setPercentage(0);
      }, 500);
    } else {
      setShow(false);
      setSelectedId(null);
    }
  };

  return (
    <div
      style={{
        backgroundColor: settings?.bgColor,
      }}
      className="min-h-screen overflow-x-hidden flex flex-col items-center"
    >
      <AnimatePresence>
        {selectedId && (
          <motion.div
            layoutId={selectedId}
            className="w-full fixed h-full overflow-x-hidden inset-0 bg-white bg-opacity z-10 "
          >
            {console.log(stats?.cards, cardId?.main?.event, "CHECK")}
            {stats?.cards?.find((card) => card?.event === cardId?.main?.event)
              ?.completed === false || task ? (
              <div className="space-y-2 h-full flex flex-col justify-between">
                <div className="space-y-2">
                  <div className="flex w-full justify-between items-center py-4 px-6">
                    <ChevronLeft
                      onClick={() => {
                        setShow(false);
                        setSelectedId(null);
                      }}
                      className="h-6 w-6"
                    />
                    <XMark
                      onClick={() => {
                        setShow(false);
                        setSelectedId(null);
                      }}
                      className="h-6 w-6"
                    />
                  </div>
                  {/* Card Locked Screen */}
                  <div className="relative flex flex-col items-center rounded-lg justify-center">
                    <img
                      className="w-52 h-52 object-cover rounded-lg"
                      src={cardId?.locked?.cardBgImageUrl}
                      alt=""
                    />
                    {/* <div className="absolute inset-0 flex items-center justify-center">
                      <img
                        className="w-36 h-36 rounded-full object-cover"
                        src={cardId?.locked?.cardIconUrl}
                        alt=""
                      />
                    </div> */}
                  </div>
                  <div className="text-2xl px-6 font-semibold text-center">
                    {cardId?.locked?.heading}
                  </div>
                  <div className="text-center px-6">
                    {cardId?.locked?.subHeading}
                  </div>
                  <div className="px-10 w-full">
                    <div className="h-[1px] bg-black w-full" />
                  </div>
                  <div className="flex items-center px-10 gap-x-2 w-full">
                    <img
                      className="h-6 w-6 object-contain"
                      src="/assets/Hourglass.png"
                      alt=""
                    />
                    <span className="text-base font-semibold">
                      Expires{" "}
                      {moment(cardId?.locked?.expiry).format("DD MMM, YYYY")}
                    </span>
                  </div>
                  <ul className="w-full flex flex-col items-center px-10 list-disc list-outside">
                    <div className="font-semibold text-xl pb-4">
                      Terms and Conditions:
                    </div>
                    {cardId?.locked?.terms?.split(".")?.map((term, index) => (
                      <li key={index} className="list-item w-full">
                        {term}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="px-8 w-full max-w-xs mx-auto">
                  <button
                    style={{
                      backgroundColor: cardId?.locked?.ctaButtonColor,
                      color: cardId?.locked?.ctaTextColor,
                    }}
                    onClick={() => buttonClick(cardId?.locked?.ctaLink)}
                    className="text-white w-full text-base mt-4 font-semibold px-6 h-12 py-2 mb-6 rounded-full bg-blue-600"
                  >
                    {cardId?.locked?.ctaButtonText}
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col overflow-hidden w-full min-h-screen items-center justify-between">
                <div className="w-full">
                  <div className="flex w-full justify-between items-center py-4 px-6 z-10">
                    <ChevronLeft
                      onClick={handleScractchCardClose}
                      className="h-6 w-6"
                    />
                    <XMark
                      onClick={handleScractchCardClose}
                      className="h-6 w-6"
                    />
                  </div>
                  <div className="flex flex-col h-full  items-center justify-between">
                    {/* Card UnLocked Screen */}
                    <div className="relative w-full flex flex-col items-center justify-center">
                      <div className="text-2xl font-bold pb-8 px-6 text-center">
                        {cardId?.unlocked?.heading}
                      </div>
                      {!stats?.cards?.find(
                        (card) => card?.event === cardId?.main?.event
                      ).scratched ? (
                        // <ScratchCard {...settingss}>
                        <div className="fixed backdrop-blur-2xl inset-0 h-full w-full flex items-center justify-center">
                          <div className="flex absolute top-0 right-0 w-full justify-between items-center py-4 px-6 z-10">
                            <ChevronLeft
                              onClick={handleScractchCardClose}
                              className="h-6 w-6"
                            />
                            <XMark
                              onClick={handleScractchCardClose}
                              className="h-6 w-6"
                            />
                          </div>
                          <Confetti
                            height={height - 10}
                            width={width - 20}
                            recycle={false}
                            tweenDuration={100}
                            numberOfPieces={300}
                          />
                          <ScratchCard
                            width={210}
                            height={210}
                            image={card}
                            finishPercent={finishPercentage}
                            setPercentage={(e) => setPercentage(e)}
                            onComplete={() => updateScratched()}
                          >
                            <div
                              className="w-full flex flex-col justify-between max-w-[220px] h-52 px-4 py-4 object-contain"
                              style={{
                                borderWidth: 2,
                                borderColor: cardId?.unlocked?.cardBorderColor,
                                borderRadius: 18,
                              }}
                              alt=""
                            >
                              <div className="text-2xl font-semibold">
                                {cardId?.main?.rewardId?.name}
                                <div className="text-sm text-gray-700 font-semibold">
                                  {cardId?.main?.rewardId?.desc}
                                </div>
                              </div>
                              <div className="">
                                <label
                                  className="text-gray-700 text-sm"
                                  htmlFor="Use Code"
                                >
                                  Use Code
                                </label>
                                <div className="h-10 flex justify-between items-center rounded-md bg-slate-200 px-2 py-1">
                                  <div className=" truncate ">
                                    {cardId?.main?.rewardId?.code?.toUpperCase()}
                                  </div>
                                  <Clipboard
                                    onClick={() =>
                                      navigator.clipboard.writeText("Text")
                                    }
                                    className="h-6 w-12 cursor-pointer"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="text-center text-lg px-6 mt-4">
                              {cardId?.unlocked?.subHeading}
                            </div> */}
                          </ScratchCard>
                        </div>
                      ) : (
                        <>
                          <div
                            className="w-full flex flex-col justify-between max-w-[220px] h-52 px-4 py-4 object-contain"
                            style={{
                              borderWidth: 2,
                              borderColor: cardId?.unlocked?.cardBorderColor,
                              borderRadius: 12,
                            }}
                            alt=""
                          >
                            <div className="text-2xl font-semibold">
                              {cardId?.main?.rewardId?.name}
                              <div className="text-sm text-gray-700 font-semibold">
                                {cardId?.main?.rewardId?.desc}
                              </div>
                            </div>
                            <div className="">
                              <label
                                className="text-gray-700 text-sm"
                                htmlFor="Use Code"
                              >
                                Use Code
                              </label>
                              <div className="h-10 flex justify-between items-center rounded-md bg-slate-200 px-2 py-1">
                                <div className=" truncate ">
                                  {cardId?.main?.rewardId?.code?.toUpperCase()}
                                </div>
                                <Clipboard
                                  onClick={() =>
                                    navigator.clipboard.writeText("Text")
                                  }
                                  className="h-6 w-12 cursor-pointer"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {stats?.cards?.find(
                        (card) => card?.event === cardId?.main?.event
                      ).scratched && (
                        <>
                          <div className="text-center text-lg px-6 pt-8">
                            {cardId?.unlocked?.subHeading}
                          </div>
                          <div className="px-6 w-full py-2">
                            <div className="h-[1px] bg-black w-full" />
                          </div>
                          <div className="flex items-center px-6 py-2 gap-x-2 w-full">
                            <img
                              className="h-6 w-6 object-contain"
                              src="/assets/Hourglass.png"
                              alt=""
                            />
                            <span className="text-base font-semibold">
                              Expires{" "}
                              {moment(cardId?.locked?.expiry).format(
                                "DD MMM, YYYY"
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="max-w-sm w-full ">
                  <ul className="w-full px-10 pt-4 pb-8 flex flex-col items-center list-disc list-outside">
                    <div className="font-semibold text-xl pb-4">
                      Terms and Conditions:
                    </div>
                    {cardId?.locked?.terms?.split(".")?.map((term, index) => (
                      <li key={index} className="list-item w-full">
                        {term}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="px-8 w-full max-w-xs">
                  <button
                    style={{
                      backgroundColor: cardId?.unlocked?.ctaButtonColor,
                      color: cardId?.unlocked?.ctaTextColor,
                    }}
                    className="text-white w-full text-base mt-4 font-semibold px-6 h-12 py-2 mb-6 rounded-full bg-blue-600"
                  >
                    {cardId?.unlocked?.ctaButtonText}
                  </button>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {/* {!show && ( */}
      <div className="flex flex-col items-center space-y-4 w-full">
        {!settings?.bannerUrl ? (
          <div className="h-[30vh] bg-gray-600 w-full" />
        ) : (
          <img
            className="h-[30vh] object-cover w-full"
            src={settings?.bannerUrl}
            alt=""
          />
        )}
        <div className="px-4 grid grid-cols-2 gap-x-4 gap-y-6 ">
          {settings?.cards?.map((_, index) =>
            stats?.cards?.length > 0 && stats?.cards[index].scratched ? (
              <motion.div
                layoutId={index + 1}
                onClick={() => {
                  setCardId(_);
                  setSelectedId(index + 1);
                  // setTimeout(() => {
                  setShow(true);
                  // }, 1000);
                }}
                className="flex relative bg-white flex-col col-span-1 justify-between w-[43vw] min-h-[43vw] px-4 py-3 object-contain"
                style={{
                  borderWidth: 2,
                  borderColor: _?.unlocked?.cardBorderColor,
                  borderRadius: 12,
                }}
                alt=""
              >
                <div className="text-xl font-semibold">
                  {_?.main?.rewardId?.name}
                  <div className="text-sm text-gray-700 font-semibold">
                    {_?.main?.rewardId?.desc}
                  </div>
                </div>
                <div className="">
                  <label className="text-gray-700 text-sm" htmlFor="Use Code">
                    Use Code
                  </label>
                  <div className="h-7 flex justify-between items-center rounded-md bg-slate-200 px-2 py-1">
                    <div className="text-sm truncate ">
                      {_?.main?.rewardId?.code?.toUpperCase()}
                    </div>
                    <Clipboard
                      onClick={() =>
                        navigator.clipboard.writeText(_?.main?.rewardId?.code)
                      }
                      className="h-4 w-8 cursor-pointer"
                    />
                  </div>
                  {new Date(_?.locked?.expiry) <= new Date() && (
                    <div className="absolute top-2 text-xs text-red-800 bg-red-200 right-2 px-2 py-1 rounded-full">
                      Expired
                    </div>
                  )}
                </div>
              </motion.div>
            ) : (
              <motion.div
                layoutId={index + 1}
                onClick={() => {
                  setCardId(_);
                  setSelectedId(index + 1);
                  // setTimeout(() => {
                  setShow(true);
                  // }, 5000);
                }}
                className="relative"
              >
                <img
                  className={` object-cover w-[43vw] h-[43vw] rounded-lg ${
                    stats?.cards?.length > 0 &&
                    !stats?.cards[index]?.completed &&
                    "grayscale"
                  } ${task && "grayscale"}`}
                  src={_?.locked?.cardBgImageUrl}
                  alt="scratch-card"
                />
                {/* <div className="absolute inset-0  flex items-center justify-center">
                  <img
                    className={`w-16 h-16 rounded-full object-cover ${
                      stats?.cards?.length > 0 &&
                      stats?.cards[index]?.completed &&
                      "grayscale"
                    } ${task && "grayscale"}`}
                    src={_?.locked?.cardIconUrl}
                    alt=""
                  />
                </div> */}
                {((stats?.cards?.length > 0 &&
                  !stats?.cards[index]?.completed) ||
                  task) && (
                  <div className="absolute bottom-6 left-0 right-0 py-1 min-h-[42px] flex items-center justify-center text-xs text-center bg-white">
                    {_?.main?.text}
                  </div>
                )}
                {new Date(_?.locked?.expiry) <= new Date() && (
                  <div className="absolute top-2 text-xs text-red-800 bg-red-200 right-2 px-2 py-1 rounded-full">
                    Expired
                  </div>
                )}
              </motion.div>
            )
          )}
        </div>
      </div>
      {/* )} */}

      {/* <ScratchCard {...settings}>Congratulations! You WON!</ScratchCard> */}
    </div>
  );
};

export default Scratch_Card;
